.home {
  width: 100%;
  align-items: center;
  font-family: Menlo, Monaco, 'Courier New', monospace;
  color: #233554;
  text-align: center;
}

/* unvisited link */
 a:link {
  color: #64ffda;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: #64ffda;
}

/* mouse over link */
a:hover {
  color: #8892b0;
}

/* selected link */
a:active {
  color: #8892b0;
} 

.about {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #0a192f;
  color: #f0f0f0;
}

.about h2 {
  font-family: Menlo, Monaco, 'Courier New', monospace;
  font-size: 80px;
  color: #ccd6f6;
  height: 50px;
}

.about .prompt {
  width: 40%;
  font-size: 30px;
}
.prompt svg {
  font-size: 60px;
  margin: 5px;
  color: #ccd6f6;
}

.skills {
  font-size: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.skills h1 {
  color: #233554;
}

.skills .list {
  list-style: none;
  width: 60%;
}

.list span {
  font-size: 20px;
}

@media only screen and (max-width: 600px) {
  .about h2 {
    font-size: 40px;
  }

  .about .prompt {
    margin-top: 10px;
    font-size: 20px;
    width: 80%;
  }

  .skills {
    text-align: center;
  }
  .list {
    padding: 0;
  }

  .skills h2 {
    font-size: 30px;
  }
}